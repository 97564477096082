export const frenchTranslation = {
    de: "Deutsch",
    fr: "Français",
    it: "Italiano",
    nl: "Nederlands",

    DE: "Deutschland",
    FR: "Frankreich",
    IT: "Italien",
    NL: "Niederlande",
    BE: "Belgien",
    CH: "Schweiz",
    AT: "Österreich",
    LU: "Luxemburg",

    yes: "Oui",
    no: "Non",
    ok: "OK",
    cancel: "Annuler",
    close: "Fermer",
    read: "Lire",
    app_info: "prowin.app - Le portail d'information pour ton activité de vente",
    logout_success: "Vous vous êtes déconnecté avec succès",
    login_again: "Se reconnecter",
    to_prowin_website:"Passer au site web proWIN",
    thank_you: "Nous vous remercions de votre visite et vous souhaitons beaucoup de succès et de plaisir avec proWIN.",
    go_back_original_account: "Retour au compte d'origine",
    switch_account: "Changer de compte",
    no_subaccount: "Aucun sous-compte disponible",
    select_country: "Sélectionner le pays",
    select_language: "Sélectionner la langue",
    change_country: "Changer de pays",
    change_language: "Changer de langue",

    error_back_to_home: "<small>Go back <a href=\"/\">to the dashboard</a>.</small>",
    error_not_found_title: "Page non trouvée",
    error_not_found_text: "La page demandée n'a pas pu être trouvée. Êtes-vous sûr qu'elle existe ?",
    error_loading_title: "Erreur de téléchargement",
    error_loading_text: "",
    error_unknown_title: "Erreur inconnue",
    error_unknown_text: "",
    error_occured: "Une erreur s'est produite.",

    dashboard_title: "Bienvenue chez proWIN international",
    dashboard_intro: "Microfrontend App Shell - Dashboard",
    demo_page_title: "Page d'exemple",

    home: "Page d'accueil",

    pwa_install: "Installer",
    pwa_reinstall: "Veuillez recharger la page si vous souhaitez revoir le bouton d'installation.",
    pwa_prompt_install: "Procurez-vous l'application proWIN.",
    pwa_enable_push: "Ajouter l'application web à l'écran d'accueil pour activer les notifications push",

    menu_label: "Menu",
    menu_home: "Page d'accueil",
    menu_general_start_page: "Page d'accueil",
    menu_general_faq: "Savoir",
    menu_general_information: "Savoir",
    menu_general_events: "Agenda",
    menu_general_competitions: "Challenges",
    menu_general_products: "Produits",
    menu_general_current: "Distribution",
    menu_product_information: "Info produit",
    menu_product_shop: "Commande en ligne",
    menu_product_complaints: "Réclamation",
    menu_general_myprowin: "Mon proWIN",
    menu_myprowin_parties: "Démonstrations",
    menu_myprowin_distribution_application: "Contrat de mandataire",
    menu_myprowin_invoices: "Factures et documents",
    menu_myprowin_reporting: "Reporting",
    menu_myprowin_distribution_website: "Page Conseiller",
    menu_myprowin_planning: "Outil de planification",
    menu_elearning: "E-Learning",
    menu_media: "proWIN Media",

    menu_subline_product_marketing: "Commander des supports publicitaires",
    menu_subline_product_info: "Informations produits générales",
    menu_subline_reporting: "Évaluations de ma structure",
    menu_subline_reclamation: "Réclamer des produits",
    menu_subline_shop: "Commander des produits",
    menu_subline_media: "Le grand portail vidéo",
    menu_subline_academy: "En découvrir plus sur l'activité de Conseiller",
    menu_subline_party_planning: "Planifier ma prochaine démonstration",
    menu_subline_structure: "Créer et gérer des évènements au sein de ma structure",
    menu_subline_vp_webseite: "Modifier ma propre page Conseiller",
    menu_subline_faq: "Base de connaissances à consulter",

    menu_name_product_marketing: "Shop Marketing",
    menu_name_product_info: "Informations produits",
    menu_name_reporting: "Reporting",
    menu_name_blumer: "Blumer",
    menu_name_reclamation: "Réclamation",
    menu_name_shop: "Commande en ligne",
    menu_name_media: "proWIN Media",
    menu_name_academy: "Académie en ligne",
    menu_name_party_planning: "Planification de démonstration",
    menu_name_vp_webseite: "Mon site VP",
    menu_name_structure: "Rendez-vous de la structure",
    menu_name_faq: "Connaissances du réseau",

    user_menu_latest: "Actualités",
    user_menu_knowledge: "Savoir",
    user_menu_dark_mode: "Mode sombre",
    user_menu_light_mode: "Mode clair",
    user_menu_profile: "Profil",
    user_menu_tasks: "Tâches",
    user_menu_select_language: "Changer de langue",
    user_menu_my_account: "Mon compte",
    user_menu_settings: "Paramétrages",
    user_menu_messages: "Messages",
    user_menu_help: "Aide",
    user_menu_contact: "Contact",
    user_menu_logout: "Se déconnecter",
    user_menu_language: "Langue",
    user_menu_country: "Pays",
    user_menu_dashboard: "Tableau de bord",
    user_menu_salespartner_edit: "Édition de la Page Conseiller",
    user_menu_app_install: "Installer l'application",

    footer_copy: "{{currentYear}} proWIN international",

    oauth_logout_headline: "Déconnecter",
    oauth_logout_text: "Déconnexion terminée",

    link_imprint_href: "/mentions légales",
    link_imprint_label: "Mentions légales",
    link_privacy_href: "/protection des données",
    link_privacy_label: "Protection des données",
    link_help_href: "/aide",
    link_help_label: "Aide",
    link_contact_href: "/contact",
    link_contact_label: "Contact",
    imprint_headline: "Mentions légales",
    privacy_headline: "Protection des données",

    notifications_choice_title: "Notifications Push",
    notifications_ask_to_get_notificatons: "Souhaitez-vous recevoir des notifications push ?",
    notifications_ask_to_get_authorization: "Pour recevoir des notifications push, vous devez les activer dans votre navigateur.",
    notifications_choice_intro: "Veuillez sélectionner les thèmes pour lesquels une notification doit être envoyée :",
    notifications_choice_denied: "La notification a été bloquée. Veuillez réinitialiser les paramètres pour activer les notifications push.",
    notifications_activated: "Les notifications sont activées.",
    notifications_turn_off: "Pour ne plus recevoir de notifications, veuillez aller dans les paramètres de cette application web sur votre appareil et désactiver les notifications.",

    notification_group_general: "Test 1",
    notification_group_marketing: "Test 2",
    notification_group_other: "Test 3",

    install_web_app_General: "L'installation de l'application proWIN n'est actuellement pas disponible dans ce navigateur. Veuillez essayer un autre navigateur.",
    install_web_app_dialog_title: "Installez l'application proWIN sur votre appareil",

    install_web_app_Firefox: "L'installation de l'application proWIN n'est actuellement pas disponible dans le navigateur Firefox.",
    install_web_app_Firefox_Tablet_1: "Cliquez sur le menu du navigateur",
    install_web_app_Firefox_Tablet_2: "Sélectionnez 'Ajouter à l'écran d'accueil' dans le menu contextuel.",
    install_web_app_Firefox_Tablet_3: "Cliquez sur la 'Page d'accueil'",
    install_web_app_Firefox_3: "Cliquez sur Installer dans la petite fenêtre pop-up et demandez-vous de confirmer votre action.",

    install_web_app_Opera: "L'installation de l'application proWIN n'est actuellement pas disponible dans le navigateur Opera.",
    install_web_app_Opera_Tablet_1: "Cliquez sur le menu du  navigateur",
    install_web_app_Opera_Tablet_2: "Sélectionnez 'Ajouter à' dans le menu contextuel.",
    install_web_app_Opera_Tablet_3: "Vous pouvez maintenant donner un nom individuel à l'application, qui sera ensuite affiché sur votre appareil avec l'icône de l'application. Le nom de l'application est présélectionné.",
    install_web_app_Opera_Tablet_4: "Cliquez sur ajouter.",

    install_web_app_Vivaldi_Desktop_1: "Cliquez avec le bouton droit de la souris sur l'onglet dans la barre d'onglets.",
    install_web_app_Vivaldi_Desktop_2: "Sélectionnez 'Installer proWIN' dans le menu contextuel.",
    install_web_app_Vivaldi_Tablet_1: "Cliquez sur le menu du  navigateur",
    install_web_app_Vivaldi_Tablet_2: "Sélectionnez 'Installer l'application' ou 'Ajouter à l'écran d'accueil' dans le menu contextuel.",
    install_web_app_Vivaldi_3: "Cliquez sur Installer dans la petite fenêtre pop-up et demandez-vous de confirmer votre action.",

    install_web_app_SAFARI_1: "Ouvrez la fonction de partage dans votre navigateur.",
    install_web_app_SAFARI_2: "Cliquez sur \"Vers l'écran d'accueil\"",
    install_web_app_SAFARI_3: "Vous pouvez maintenant donner un nom individuel à l'application, qui sera ensuite affiché sur votre appareil avec l'icône de l'application. Le nom de l'application est présélectionné.",
    install_web_app_SAFARI_4: "Cliquez sur ajouter.",

    label_firstname: "Prénom",
    label_lastname: "Nom",
    label_birthday: "Anniversaire",
    label_title: "Civilité",
    label_address: "Adresse",
    label_phone: "Téléphone",
    label_email: "E-mail",
    label_company: "Société",
    label_street: "Rue",
    label_housenumber: "Numéro de maison",
    label_postalcode: "CP",
    label_city: "Ville",
    label_companyname: "Raison sociale",
    label_div: "Divers",
    label_type_private: "Privé",
    label_type_company: "Professionnel",
    label_type_div: "Divers",
    label_note: "Notes",
    label_products_desired: "Produits souhaités",
    label_contact_request: "Contact souhaité",
    label_contact_request_phone: "Téléphone",
    label_contact_request_chat: "Chat",
    label_contact_request_post: "Courrier",
    label_contact_request_email: "E-mail",
    label_contact_time: "Horaires de contact",
    label_contact_time_weekly: "Jours ouvrables",
    label_contact_time_weekend: "Week-end",
    label_contact_time_morning: "le matin",
    label_contact_time_afternoon: "l'après-midi",
    label_contact_time_evening: "le soir",

    title_mr: "Monsieur",
    title_ms: "Madame",
    title_div: "Divers",

    network_status: "Vous êtes hors ligne",
    de_faq_home_headline: "Home bla bla bla ...",

    feature_later: 'Cette fonction sera ajoutée ultérieurement',
    feature_only_for_vp: 'Cette fonction est réservée aux Conseillers',

    texthing_error: "Erreur lors de la récupération des données ou celles-ci ne sont pas disponibles dans cette langue.",

    employee_logged_as: "Vous êtes connecté en tant que collaborateur. Veuillez sélectionner une option pour continuer.",
    employee_to_switch_login: "Vers le Switch-Login",

    product_sub_navigation: "Sous-navigation des produits",
}